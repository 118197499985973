export default {
  isloggedIn(state) {
    if (state.token === null || state.operator.id === null) return false
    return true
  },
  operatorEcosystem(state) {
    return state.ecosystem
  },
  operatorPhoneNumber(state) {
    return state.operator.phoneNumber
  },
  operatorName(state) {
    return state.operator.name
  },
  operator(state) {
    return {
      id: state.operator.id,
      role: state.operator.role,
      name: state.operator.name,
      phoneNumber: state.operator.phoneNumber,
      avatar: state.operator.avatar,
      ecosystems: state.operator.ecosystems,
    }
  },
  currentUser(state) {
    return state.currentUser
  },
  snackbarOptions(state) {
    return state.snackbar
  },
}
