/*
    Auth
*/

export const CHECK_OPERATOR_QUERY = `
  query ($phoneNumber: String!) {
    checkOperator (phoneNumber: $phoneNumber) {
      exists
      blocked
    }
  }
`

export const SEND_OTP_SMS_MUTATION = `
  mutation ($input: SendOtpSmsInput!) {
    sendOtpSms (input: $input) {
      type
    }
  }
`

export const RESEND_OTP_SMS_MUTATION = `
  mutation ($input: ResendOtpSmsInput!) {
    resendOtpSms (input: $input) {
      type
    }
  }
`

export const LOGIN_OTP_MUTATION = `
  mutation ($input: LoginOperatorOtpInput!) {
    loginOperatorOtp(input: $input) {
      token
      operator {
        id
        role
        name
        phoneNumber
        avatar
        blocked
        ecosystems {
          ecosystem {
            id
            slug
            name
            electricBikesEnabled
            operatorCallsAllowed
            status
          }
          active
        }
      }
    }
  }
`

export const UPDATE_ACTIVE_ECOSYSTEM_MUTATION = `
  mutation ($input: UpdateActiveOperatorEcosystemInput!) {
    updateActiveOperatorEcosystem(input: $input) {
      id
      role
      name
      phoneNumber
      avatar
      blocked
      ecosystems {
        ecosystem {
          id
          slug
          name
          electricBikesEnabled
          operatorCallsAllowed
          status
        }
        active
      }
    }
  }
`

/* Stations */

export const STATIONS_QUERY = `
  query ($first: Int!, $state: [String]!, $ecosystem: String!) {
    stations(
      first: $first,
      state: $state,
      ecosystem: $ecosystem
    ) {
      edges {
        node {
          id
          name
          docks {
            id
            address
            latchState
            dockState
            updatedAt
            status
            bike {
              number
              type
            }
          }
          latitude
          longitude
          state
        }
      }
    }
  }
`

export const GET_STATION_QUERY = `
  query ($id: Int!) {
    stations(id: $id) {
      edges {
        node {
          id
          name
          docks {
            id
            address
            qrIdentifier
            latchState
            dockState
            updatedAt
            status
            bike {
              number
              type
            }
          }
        }
      }
    }
  }
`

export const TRIGGER_DOCK_MUTATION = `
  mutation ($input: TriggerDockInput!) {
    triggerDock(input: $input) {
      id
      name
    }
  }
`

export const RESTART_DOCK_MUTATION = `
  mutation ($input: RestartDockInput!) {
    restartDock(input: $input) {
      id
      name
    }
  }
`

export const UPDATE_DOCK_STATUS_MUTATION = `
  mutation ($input: UpdateDockStatusInput!) {
    updateDockStatus(input: $input) {
      id
    }
  }
`

/*
    Ongoing
*/

export const ONGOING_RIDES_QUERY = `
  query ($first: Int!, $ecosystem: String!) {
    ongoingRides(
      first: $first
      ecosystem: $ecosystem
    ) {
      edges {
        node {
          id
          user {
            name
            phoneNumber
          }
          bike {
            number
            type
          }
          fromStation {
            name
          }
          startTime
        }
      }
    }
  }
`

/*
    Tasks
*/

export const TASKS_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $group: OperatorTasksGroupEnum!, $ecosystem: String!) {
    currentOperator {
      tasks(
        after: $after
        first: $first,
        before: $before,
        last: $last,
        group: $group,
        ecosystem: $ecosystem
      ) {
        edges {
          node {
            id
            status
            type
            creatorComment
            issues {
              type
              name
              identifier
              accepted
            }
            creatorPhotos
            bike {
              id
              number
              type
            }
            station {
              id
              name
            }
            dockAddress
            creatorUser {
              id
            }
            creatorAdmin {
              id
            }
            creatorOperator {
              id
              name
            }
            resolverOperator {
              id
              name
            }
            resolverAdmin {
              id
              name
            }
            resolverComment
            closedAt
            createdAt
            updatedAt
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
      }
    }
  }
`

export const TASK_QUERY = `
  query ($id: Int!) {
    task(id: $id) {
      id
      status
      type
      creatorComment
      issues {
        type
        name
        identifier
        accepted
      }
      creatorPhotos
      bike {
        id
        number
        type
      }
      station {
        id
        name
      }
      dockAddress
      creatorUser {
        id
      }
      creatorAdmin {
        id
      }
      creatorOperator {
        id
        name
      }
      resolverOperator {
        id
        name
      }
      resolverAdmin {
        id
        name
      }
      resolverPhotos
      resolverComment
      closedAt
      createdAt
      updatedAt
    }
  }
`

export const TASK_ISSUES_QUERY = `
  query {
    taskIssues {
      id
      type
      identifier
      name
      isActive
      creatorRoles
    }
  }
`

export const TASK_PHOTO_URL_MUTATION = `
  mutation {
    createTaskPhotoUrl {
      fileName
      url
    }
  }
`

export const UPDATE_TASK_MUTATION = `
  mutation ($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      id
      status
    }
  }
`

export const CREATE_TASK_MUTATION = `
  mutation ($input: CreateTaskInput!) {
    createTask(input: $input) {
      taskIds
    }
  }
`

/*
    Attendance
*/

export const OPERATOR_LOG_PHOTO_URL_MUTATION = `
  mutation {
    createOperatorLogPhotoUrl {
      fileName
      url
    }
  }
`

export const CREATE_OPERATOR_LOG_MUTATION = `
  mutation ($input: CreateOperatorLogInput!) {
    createOperatorLog(input: $input) {
      id
    }
  }
`

export const OPERATOR_LOGS_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $ecosystem: String) {
    operatorLogs(
      after: $after
      first: $first,
      before: $before,
      last: $last,
      ecosystem: $ecosystem
    ) {
      edges {
        node {
          id
          operator {
            id
            name
          }
          station {
            id
            name
            latitude
            longitude
          }
          bikes {
            bike {
              id
              number
            }
          }
          type
          photos
          latitude
          longitude
          createdAt
          ecosystem {
            name
            slug
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

/* Bikes */

export const BIKES_QUERY = `
  query ($first: Int!, $ecosystem: String!, $state: [String!]!) {
    bikes(
      first: $first
      ecosystem: $ecosystem
      state: $state
    ) {
      edges {
        node {
          id
          number
          type
        }
      }
    }
  }
`

export const BIKE_STATS_QUERY = `
  query ($ecosystem: String!) {
    bikeStats (ecosystem: $ecosystem) {
      deployed
      maintenance
      charging
    }
  }
`

export const GET_BIKE_QUERY = `
  query ($id: Int) {
    bike (id: $id) {
      id
      number
      type
      state
    }
  }
`

export const UPDATE_BIKE_STATE_MUTATION = `
  mutation ($input: UpdateBikeStateInput!) {
    updateBikeState(input: $input) {
      id
      number
      type
      state
    }
  }
`

/* Unlock */
export const DOCK_QUERY = `
  query ($qrToken: String!) {
    dock(qrToken: $qrToken) {
      station {
        id
        name
      }
      address
    }
  }
`

/* Profile */

export const OPERATOR_PROFILE_QUERY = `
  query {
    currentOperator {
      id
      role
      name
      phoneNumber
      avatar
      blocked
      ecosystems {
        ecosystem {
          id
          slug
          name
          electricBikesEnabled
          operatorCallsAllowed
          status
        }
        active
      }
    }
  }
`

export const OPERATOR_AVATAR_URL_MUTATION = `
  mutation {
    createOperatorAvatarUrl {
      fileName
      url
    }
  }
`

export const UPDATE_OPERATOR_AVATAR_MUTATION = `
  mutation ($input: UpdateOperatorAvatarInput!) {
    updateOperatorAvatar(input: $input) {
      avatar
    }
  }
`
